import axios from "axios";
import { apiUrl } from "../config/settings.json";

const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("@TOKENSELLER");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (resp) => {
    if (resp?.status === 401) {
      localStorage.clear();
    }
    return resp;
  },
  (err) => {
    if (err?.response?.status === 401) {
      window.location.reload();
      localStorage.clear();
      window.location.reload();
    } else {
      return Promise.reject(err);
    }
  }
);

export default api;
