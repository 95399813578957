import { useEffect, useRef } from "react";
import { useField } from "@unform/core";
export default function Input({ name, type, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField} = useField(name);

  useEffect(() => {
    if(type === 'file') {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: "files[0]",
      });
    }
    else{
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: "value",
      });
    }
  }, [fieldName, registerField]);

  return <input ref={inputRef} {...rest} type={type} />;
}
