import "./header.css";
import { useContext } from "react";
import LogoImage from "../../assets/logo.svg";
import BellImage from "../../assets/bell.svg";
import UserLightImage from "../../assets/user-light.svg";
import { Context } from "../../context";

export default function Header() {
  const context = useContext(Context);
  return (
    <header
      className="bg-dark d-flex align-items-center justify-content-between px-md-5 px-0 p-3"
    >
      <img src={LogoImage} />
      <span className="d-sm-flex flex-column flex-sm-row align-items-center ml-auto mr-md-5 mr-0 d-none">
        <img
          src={BellImage}
          style={{ width: 30 }}
          className="ml-auto d-none d-sm-flex mr-5"
        />
        <img src={UserLightImage} style={{ width: 28 }} />
        <p className="text-light">
          <strong>{context.username}</strong>
        </p>
      </span>
      <button
        className="btn btn-outline-light d-inline d-sm-none ml-auto mr-2"
        onClick={() => context.setShowAside(!context.showAside)}
      >
        <div class="container-btn">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </button>
    </header>
  );
}
