import { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import api from "../../../services/api";
import { Form } from "@unform/web";
import { Scope } from "@unform/core";
import Input from "../../../Components/Form/input";
import Textarea from "../../../Components/Form/textarea";
import Select from "../../../Components/Form/select";
import { toast } from "react-toastify";

export default function UpdateConfigs(props) {
  const formRef = useRef(null);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regDocFile, setRegDocFile] = useState(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data: statesDb } = await api.get("/api/estados");
      setStates(statesDb);

      const { data } = await api.get("/usuario");

      const reduceData = data.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr,
        };
      }, {});
      const { data: citiesDb } = await api.get(
        `/api/cidade/${reduceData.state}`
      );
      setCities(citiesDb);

      function FormataStringData(data) {
        const day = data.split("/")[1];
        const month = data.split("/")[0];
        const year = data.split("/")[2];

        return (
          year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2)
        );
      }

      formRef.current?.setData({
        ...reduceData,
        telefone1: reduceData.WhatsApp || "",
        telefone2: reduceData.primary_phone || "",
        telefone3: reduceData.secondary_phone || "",
        Chute_fee: +reduceData.Chute_fee.replace(/\D/g, "") / 100,
        date_of_birth: FormataStringData(reduceData.date_of_birth),
        regNumber: reduceData.register_Number,
        address: {
          city: reduceData.city,
          number: reduceData.number,
          state: reduceData.state,
          street: reduceData.street,
          zip_code: reduceData.zip_code,
        },
      });
      setLoading(false);
    })();
  }, [props, formRef]);

  const handleSubmit = async (data) => {
    try {
      await api.put("/usuario/vendedor", data);
      if (regDocFile) {
        const formData = new FormData();
        formData.append("file", regDocFile);
        await api.post("/usuario/document-registro", formData);
      }
      props.onHide();
      return toast.success("Data successfully modified!");
    } catch (err) {
      return toast.error(err.response.data.message);
    }
  };
  return (
    <Modal {...props} size="xl">
      <Modal.Body size="xl">
        <div className="d-flex justify-content-between">
          <h3>My Account Edit</h3>
          <button className="btn btn-outline-danger" onClick={props.onHide}>
            <FaTimes color="#990000" />
          </button>
        </div>
        <hr style={{ margin: "5px 0 15px", borderTop: "3px solid #aaa" }} />
        <Form onSubmit={handleSubmit} ref={formRef}>
          <div className="d-flex justify-content-between">
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              Legal Business Name
              <Input
                name="name"
                className="form-control"
                placeholder="Something ORG LLC Limited"
              />
            </label>
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              Business Registration Number (EIN)
              <Input
                name="regNumber"
                type="number"
                className="form-control"
                placeholder="9999999"
              />
            </label>
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              Company Creation Date
              <Input
                name="date_of_birth"
                className="form-control"
                placeholder=""
                type="date"
              />
            </label>
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              WhatsApp
              <Input
                name="telefone1"
                className="form-control"
                placeholder="+1 000 000000"
                type="number"
              />
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              Telefone #1
              <Input
                name="telefone2"
                className="form-control"
                placeholder="+1 000 000000"
                type="number"
              />
            </label>
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              Telefone #2
              <Input
                name="telefone3"
                className="form-control"
                placeholder="+1 000 000000"
                type="number"
              />
            </label>
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              Email Address
              <Input
                name="email"
                type="email"
                className="form-control"
              />
            </label>
            <label className="label-control w-100 mx-2 text-nowrap text-truncate">
              Default chutefee
              <Input
                name="Chute_fee"
                className="form-control"
                type="number"
                placeholder="USD 0000.00"
              />
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label className="label-control w-100 px-2 text-nowrap text-truncate col-3">
              Company type
              <Input
                name="type"
                className="form-control"
                placeholder="C Corp / S Corp / LLC / Nonprofit Org"
              />
            </label>
            <label className="label-control w-100 px-2 text-nowrap text-truncate col-3">
              Company Website
              <Input
                name="site"
                className="form-control"
                placeholder="www.anything.com"
              />
            </label>
            <label className="label-control w-100 px-2 text-nowrap text-truncate col-4">
              Company Address
              <Input
                name="address.street"
                className="form-control"
                placeholder="somenthing street"
              />
            </label>
            <label className="label-control w-100 text-nowrap text-truncate col-2 pr-1">
              Address Number
              <Input
                name="address.number"
                className="form-control"
                placeholder="0505"
                type="number"
              />
            </label>
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <div className="d-flex">
                <label className="label-control w-100 mx-2 text-nowrap text-truncate">
                  State
                  <Select
                    name="address.state"
                    className="form-control"
                    onChange={async (e) => {
                      const { data } = await api.get(
                        `/api/cidade/${e.target.value}`
                      );
                      setCities(data);
                    }}
                  >
                    <option>States</option>
                    {states.map((state) => {
                      return (
                        <option value={state.state_name} key={state.state_name}>
                          {state.state_name}
                        </option>
                      );
                    })}
                  </Select>
                </label>
                <label className="label-control w-100 mx-2 text-nowrap text-truncate">
                  City
                  <Select name="address.city" className="form-control">
                    <option>Cities</option>
                    {cities.length <= 0 && (
                      <option disabled>please select your state</option>
                    )}
                    {cities.map((city) => {
                      return (
                        <option value={city.city_name} key={city.city_name}>{city.city_name}</option>
                      );
                    })}
                  </Select>
                </label>
              </div>
              <div className="d-flex">
                <label className="label-control w-100 mx-2 text-nowrap text-truncate">
                  Postal code / zip code
                  <Input
                    name="address.zip_code"
                    className="form-control"
                    type="number"
                  />
                </label>
                <div className="input-group pl-1">
                  <label clssName="label-control">
                    Company registration document
                    <div className="custom-file">
                      <Input
                        name="reg_doc"
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile02"
                        onChange={(e) => setRegDocFile(e.target.files[0])}
                      />
                      <label
                        className="custom-file-label"
                        for="inputGroupFile02"
                      >
                        Choose file
                      </label>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <label className="label-control w-50 mx-2 text-nowrap text-truncate d-flex flex-column">
              Company description
              <Textarea name="Description" className="w-100 form-control" />
            </label>
          </div>
          <h4>Banking Details</h4>
          <Scope path="bank">
            <div className="d-flex justify-content-between">
              <label className="label-control w-50 mx-2 text-nowrap text-truncate d-flex flex-column col-3 p-0">
                Branch Code (Routing Number)
                <Input
                  name="routing_number"
                  className="w-100 form-control"
                  placeholder="112233445566"
                  type="number"
                />
              </label>
              <label className="label-control w-50 mx-2 text-nowrap text-truncate d-flex flex-column col-3 p-0">
                Account Number
                <Input
                  name="account_number"
                  className="w-100 form-control"
                  placeholder="112233445566"
                  type="number"
                />
              </label>
              <label className="label-control w-50 mx-2 text-nowrap text-truncate d-flex flex-column col-2 p-0">
                Account type
                <Select name="account_type" className="form-control">
                  <option disabled selected>
                    Cheking / Saving
                  </option>
                  <option>Saving</option>
                  <option>Cheking</option>
                </Select>
              </label>

              <label className="label-control col-3 p-0 text-nowrap text-truncate">
                Company Shareholder Passport Copy
                <div className="input-group">
                  <div className="custom-file d-flex flex-column">
                    <Input
                      name="passport"
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile02"
                    />
                    <label className="custom-file-label" for="inputGroupFile02">
                      Choose file
                    </label>
                  </div>
                </div>
              </label>
            </div>
            <div className="d-flex justify-content-between">
              <label className="label-control w-50 mx-2 text-nowrap text-truncate d-flex flex-column col-2 p-0">
                Bank Code
                <Input
                  name="code"
                  className="w-100 form-control"
                  placeholder="112233445566"
                  type="number"
                />
              </label>
              <label className="label-control w-50 mx-1 text-nowrap text-truncate d-flex flex-column col-6 p-0">
                Bank Name
                <Input name="name" className="form-control" />
              </label>

              <label className="label-control col-3 p-0 text-nowrap text-truncate">
                Actual Bancking statement
                <div className="input-group">
                  <div className="custom-file d-flex flex-column">
                    <Input
                      name="statement"
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile02"
                    />
                    <label className="custom-file-label" for="inputGroupFile02">
                      Choose file
                    </label>
                  </div>
                </div>
              </label>
            </div>
          </Scope>
          <hr style={{ margin: "15px 0 ", borderTop: "3px solid #aaa" }} />
          {!loading && (
            <div className="d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-outline-danger mx-3"
                onClick={props.onHide}
              >
                Cancel Edit
              </button>
              <button type="submit" className="btn btn-info">
                Save Changes
              </button>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
