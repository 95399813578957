import {useState } from "react";
import api from "../../services/api";

import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import { Spinner } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { BiMessage } from "react-icons/bi";
import { toast } from "react-toastify";

import Button from "../../Components/Button";

export default function PromotionPage() {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const sendEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await api.post("/sendEmail", {
        title: title,
        message: message,
      });
      setLoading(false);
      toast.success("Email Send successfully!");

    } catch (e) {
      setLoading(false)
      toast.error(e.response.data.message);
    }
  };
  const sendMessageSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      await api.post("/sendMessage", {
        title: title,
        message: message,
      });
      setLoading(false)
      toast.success("Message Send successfully!");
    } catch (e) {
      setLoading(false)
      toast.error(e.response.data.message);
    }
  };

  return (
    <>
      <Header />

      <div className="d-flex" style={{ minHeight: "90vh" }}>
        <div className="bg-dark col-12 col-sm-3 col-lg-2 px-0">
          <Aside />
        </div>
        <main
          className="p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize">Promotion</h3>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div style={{ background: "#fff" }} className="p-3 px-5 mt-5">
              <div>
                <label className="d-flex flex-column w-100">
                  Title
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ borderWidth: 2, borderRadius: 5 }}
                    className="pl-1 form-control"
                  />
                </label>
                <label className="d-flex flex-column w-100">
                  Message
                  <textarea
                    type="text"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    style={{ borderWidth: 2, borderRadius: 5, height: 200 }}
                    className="pl-1 form-control"
                  />
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <Button onClick={sendEmailSubmit}>
                  Send Email
                  <AiOutlineMail size={30} className="ml-2" />
                </Button>
                <div style={{ marginLeft: 10 }}>
                  <Button onClick={sendMessageSubmit}>
                    Send Message
                    <BiMessage size={30} className="ml-2" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
