import "./orders.css";
import { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import api from "../../services/api";
import {
  FaArrowLeft,
  FaArrowRight,
  FaFileExcel,
  FaPrint,
} from "react-icons/fa";
import ModalOrders from "../../Components/Modals/Orders";
import { Form } from "@unform/web";
import Input from "../../Components/Form/input";
import Select from "../../Components/Form/select";
import { toast } from "react-toastify";

export default function Financial() {
  const params = useParams();
  const history = useHistory();
  const formRef = useRef(null);
  const tableRef = useRef(null);
  const linkRef = useRef(null);

  const [items, setItem] = useState({ data: [] });
  const [mostModal, setMostModal] = useState(false);
  const [formItems, setFormItems] = useState(null);

  const page = window.location.search;

  useEffect(() => {
    (async () => {
      setItem({ data: [] });
      if (formItems) {
        submit();
      } else {
        const { data } = await api.get(
          `/orders?page=${Number(page.split("=")[1]) || 1}`
        );
        setItem(data);
        setFormItems(data);
      }
    })();
  }, [params, formItems]);

  useEffect(() => {
    if (!mostModal) {
      setMostModal(false);
    }
  }, [mostModal]);

  async function CallApi() {
    const { data } = await api.get(
      `/orders?page=${Number(page.split("=")[1])}`
    );
    setItem(data);
    setFormItems(data);
  }

  async function submit() {
    const data = formRef.current?.getData() || {};
    let url = `/orders-filter?page=${Number(page.split("=")[1])}`;
    if (data.stallion_name >= 1) {
      url = url + `&idgaranhao=${data?.stallion_name}`;
    }
    if (data.broadmare_name >= 1) {
      url = url + `&idegua=${data?.broadmare_name}`;
    }
    if (data.buyer_name !== "") {
      url = url + `&buyer_name=${data?.buyer_name}`;
    }
    if (data.order_status !== "default") {
      url = url + `&order_status=${data?.order_status}`;
    }
    if (data.start_date !== "") {
      url = url + `&start_date=${data?.start_date + " 00:00:00"}`;
    }
    if (data.finaly_date !== "") {
      url = url + `&finaly_date=${data?.finaly_date + " 00:00:00"}`;
    }
    if (!formItems?.salesman && formItems.salesman !== "") {
      url = url + `&sales_man=${formItems?.salesman}`;
    }
    try {
      const { data } = await api.get(url);
      setItem(data);
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  async function printFunction() {
    const formData = formRef.current.getData();
    let url = `/orders-filter/all?page=1`;

    if (formData.stallion_name >= 1) {
      url = url + `&idgaranhao=${formData?.stallion_name}`;
    }
    if (formData.broadmare_name >= 1) {
      url = url + `&idegua=${formData?.broadmare_name}`;
    }
    if (formData.buyer_name !== "") {
      url = url + `&buyer_name=${formData?.buyer_name}`;
    }
    if (formData.order_status !== "default") {
      url = url + `&order_status=${formData?.order_status}`;
    }
    if (formData.start_date !== "") {
      url = url + `&start_date=${formData?.start_date + " 00:00:00"}`;
    }
    if (formData.finaly_date !== "") {
      url = url + `&finaly_date=${formData?.finaly_date + " 00:00:00"}`;
    }
    if (!formData?.salesman && formData.salesman !== "") {
      url = url + `&sales_man=${formData?.salesman}`;
    }
    const {
      data: { data: dataResponse },
    } = await api.get(url);
    setItem({ ...items, data: dataResponse });

    window.print();

    const { data: dataRes } = await api.get(
      `/orders?page=${Number(page.split("=")[1])}`
    );
    setItem(dataRes);
  }

  const excel = async () => {
    const formData = formRef.current.getData();
    let url = `/orders-filter/all?page=1`;

    if (formData.stallion_name >= 1) {
      url = url + `&idgaranhao=${formData?.stallion_name}`;
    }
    if (formData.broadmare_name >= 1) {
      url = url + `&idegua=${formData?.broadmare_name}`;
    }
    if (formData.buyer_name !== "") {
      url = url + `&buyer_name=${formData?.buyer_name}`;
    }
    if (formData.order_status !== "default") {
      url = url + `&order_status=${formData?.order_status}`;
    }
    if (formData.start_date !== "") {
      url = url + `&start_date=${formData?.start_date + " 00:00:00"}`;
    }
    if (formData.finaly_date !== "") {
      url = url + `&finaly_date=${formData?.finaly_date + " 00:00:00"}`;
    }
    if (!formData?.salesman && formData.salesman !== "") {
      url = url + `&sales_man=${formData?.salesman}`;
    }
    const {
      data: { data: dataResponse },
    } = await api.get(url);
    setItem({ ...items, data: dataResponse });

    let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    tab_text =
      tab_text +
      "<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>";
    tab_text = tab_text + "<x:Name>Fastsemem_Report</x:Name>";
    tab_text =
      tab_text +
      "<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>";
    tab_text =
      tab_text + "</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>";
    tab_text = tab_text + "<table border='1px'>";

    tab_text = tab_text + document.getElementById("table-to-xls").innerHTML;
    tab_text = tab_text + "</table></body></html>";

    const data_type = "data:application/vnd.ms-excel";

    linkRef.current.href = data_type + ", " + encodeURIComponent(tab_text);

    linkRef.current.click();
    formRef?.current?.setData(formData);
  };

  return (
    <>
      <div className="deleteForPint">
        <a ref={linkRef} className="d-none" download="MyReport_Fastsemen"></a>
        <Header />
      </div>
      <div className="d-flex page" style={{ minHeight: "90vh" }}>
        <div className="bg-dark col-12 col-sm-3 col-lg-2 px-0 deleteForPint">
          <Aside />
        </div>
        <main
          className="p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize text-center deleteForPint">
            Financial
          </h3>
          <hr className="color-dark bg-dark deleteForPint" />
          <div className="p-4 py-2 mt-4 bg-white">
            <Form
              onSubmit={(data) => {}}
              ref={formRef}
              className="deleteForPint"
            >
              <div className="d-flex justify-content-end align-items-baseline">
                <p>Export to:</p>
                <button
                  className="btn p-0 border-0"
                  type="button"
                  onClick={excel}
                >
                  <FaFileExcel size={25} />
                </button>
                <button
                  className="btn p-0 border-0"
                  type="button"
                  onClick={printFunction}
                >
                  <FaPrint size={25} className="btn p-0 border-0" />
                </button>
              </div>
              <div className="d-flex flex-wrap">
                <label className="label-control col-4">
                  Stallion Name
                  <Select name="stallion_name" className="form-control">
                    <option value="0">All/Names</option>
                    {items?.filtro?.cavalos.map((item) => {
                      return (
                        <option value={item.idgaranhao} key={item.idgaranhao}>{item.nome}</option>
                      );
                    })}
                  </Select>
                </label>
                <label className="label-control col-4">
                  Broadmare Name
                  <Select name="broadmare_name" id="" className="form-control">
                    <option value="0">All/Names</option>
                    {items?.filtro?.eguas.map((item) => {
                      return <option value={item.idegua} key={item.idegua}>{item.nome}</option>;
                    })}
                  </Select>
                </label>
                <label className="label-control col-4">
                  User
                  <Input
                    name="buyer_name"
                    placeholder="Someone"
                    className="form-control"
                  />
                </label>
                <label className="label-control col-4">
                  Stallion Stations
                  <Input
                    name="salesman"
                    placeholder="Someone"
                    className="form-control"
                  />
                </label>
                <label className="label-control col-4">
                  Order Status
                  <Select name="order_status" id="" className="form-control">
                    <option value="default">Status</option>
                    {items?.filtro?.status.map((item) => {
                      return (
                        <option value={item.status} key={item.statusName}>{item.statusName}</option>
                      );
                    })}
                  </Select>
                </label>

                <label className="label-control col-4">
                  Start Date
                  <Input
                    name="start_date"
                    className="form-control"
                    type="date"
                  />
                </label>
                <label className="label-control col-4">
                  Finaly Date
                  <Input
                    name="finaly_date"
                    className="form-control"
                    type="date"
                  />
                </label>
                <div className="d-flex col-12">
                  <div className="col-6 h-100 pl-0">
                    <button
                      type="submit"
                      className="btn btn-info w-100 h-100"
                      onClick={submit}
                    >
                      Filter
                    </button>
                  </div>
                  <div className="col-6 h-100 pr-0">
                    <button
                      type="reset"
                      className="btn btn-danger w-100 h-100"
                      onClick={async () => {
                        const { data } = await api.get(
                          `/orders?page=${Number(page.split("=")[1]) || 1}`
                        );
                        setItem(data);
                        formRef.current?.setData({});
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </Form>
            <hr className="color-dark bg-dark deleteForPint" />
            <div className="table-responsive rotateForPrint">
              <table
                className="table w-100 px-2 mt-2 bg-white"
                ref={tableRef}
                id="table-to-xls"
              >
                <thead className="px-2">
                  <tr>
                    <th className="text-nowrap">Number</th>
                    <th className="text-nowrap">Amount (US $)</th>
                    <th className="text-nowrap">Stallion</th>
                    <th className="text-nowrap">Broodmare</th>
                    <th className="text-nowrap">User</th>
                    <th className="text-nowrap">Stallion Stations</th>
                    <th className="text-nowrap">Order Date</th>
                    <th className="text-nowrap">Order Status</th>
                    <th className="text-nowrap">Used coupon</th>
                    <th className="text-nowrap">Comission</th>
                    <th className="text-nowrap">Seller Receivable</th>
                  </tr>
                </thead>
                <tbody className="px-2">
                  {items?.data?.map((item) => {
                    const idcompra =
                      item.vendedor.slice(0, 3).toUpperCase() + item.idcompra;
                    return (
                      <>
                        <tr key={`${item.egua}${item.garanhao}`}>
                          <td className="text-nowrap">{idcompra}</td>
                          <td className="text-nowrap">USD$ {item.valor}</td>
                          <td className="text-nowrap">{item.garanhao}</td>
                          <td className="text-nowrap">{item.egua}</td>
                          <td className="text-nowrap">{item.comprador}</td>
                          <td className="text-nowrap">{item.vendedor}</td>
                          <td className="text-nowrap">{item.date}</td>
                          <td className="text-nowrap">{item.status}</td>
                          <td
                            className={`${
                              item.chutefee === 1
                                ? "text-success"
                                : "text-danger"
                            } text-nowrap`}
                          >
                            {item.chutefee === 1 ? "Yes" : "No"}
                          </td>
                          <td className="text-nowrap">
                            USD$ {item.comissao_admin}
                          </td>
                          <td className="text-nowrap">
                            USD$ {+item.valor - +item.comissao_admin}
                          </td>
                        </tr>
                        {/* <br /> */}
                      </>
                    );
                  })}
                </tbody>
              </table>

              <div className="justify-content-end deleteForPint pagination">
                <span className="d-flex mr-auto d-block align-items-baseline">
                  <button
                    style={{ boxShadow: "1px 1px 6px #aaa" }}
                    className="btn btn-light mx-1"
                    onClick={() => {
                      if (Number(page.split("=")[1]) > 1) {
                        return history.push(
                          `?page=${Number(page.split("=")[1]) - 1}`
                        );
                      }
                    }}
                  >
                    <FaArrowLeft size={15} color="#000" />
                  </button>
                  <p>
                    {Number(page.split("=")[1]) || 1}/{items.max}
                  </p>
                  <button
                    style={{ boxShadow: "1px 1px 6px #aaa" }}
                    className="btn btn-light mx-1"
                    onClick={() => {
                      if (Number(page.split("=")[1] < items.max)) {
                        return history.push(
                          `?page=${Number(page.split("=")[1]) + 1}`
                        );
                      }
                    }}
                  >
                    <FaArrowRight size={15} color="#000" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ModalOrders
        show={mostModal.most}
        onHide={() => setMostModal({ most: false })}
        idsale={mostModal.data}
        param={params.param}
        callApi={() => CallApi()}
        style={{ minWidth: "100%" }}
        className="modal-order"
      />
    </>
  );
}
