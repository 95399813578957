import React, { useState, useEffect } from "react";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { Spinner } from "react-bootstrap";

import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import Select from "../../Components/Form/select";
import Input from "../../Components/Form/input";
import AddCoupon from "../../Components/Modals/AddCoupon";
import { toast } from "react-toastify";
export default function Cupons() {
  const [loading, setLoading] = useState(false);
  const [mostModal, setMostModal] = useState(false);
  const [cupoms, setCupoms] = useState([]);
  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState(1);
  const history = useHistory();

  const page = window.location.search.split("=")[1];

  useEffect(() => {
    (async () => {
      const page = window.location.search.split("=")[1];
      if (!page) {
        history.push("?page=1");
      }
      setLoading(true);
      const { data } = await api.get(`/cupom?page=${+page}`);
      setCupoms(data.cupons);
      setPages(data.maxPage);
      setUsers(data.users);
      setLoading(false);
    })();
  }, [page, window.location]);

  const deleteCoupon = async (idcupom) => {
    try {
      await api.delete(`/cupom?idcupom=${idcupom}`);
      toast.success("Coupon deleted successfully!");
    } catch (err) {
      toast.error(err.response.data.message);
    }
    const page = window.location.search.split("=")[1];
    if (!page) {
      history.push("?page=1");
    }
    const { data } = await api.get(`/cupom?page=${+page}`);
    setCupoms(data.cupons);
    setPages(data.maxPage);
    setUsers(data.users);
  };

  const submitFilter = async (data) => {
    let url = `/cupom?page=1`;
    if (!data.start_date) {
      url = url + `&start_date=${data.start_date}`;
    }
    if (!data.finaly_date) {
      url = url + `&final_date=${data.finaly_date}`;
    }
    if (+data.status && data.status !== "null") {
      url = url + `&status=${+data.status - 1}`;
    }
    if (!data.buyer && data.buyer !== "null") {
      url = url + `&buyer=${+data.buyer}`;
    }
    const { data: dataApi } = await api.get(url);
    setCupoms(dataApi.cupons);
    setPages(dataApi.maxPage);
    setUsers(dataApi.users);
  };
  return (
    <>
      <Header />
      <AddCoupon
        show={mostModal}
        onHide={async () => {
          const page = window.location.search.split("=")[1];
          if (!page) {
            history.push("?page=1");
          }
          const { data } = await api.get(`/cupom?page=1`);
          history.push("?page=1");
          setCupoms(data.cupons);
          setPages(data.maxPage);
          setUsers(data.users);
          setMostModal(false);
        }}
      />
      <div className="d-flex" style={{ minHeight: "90vh" }}>
        <div className="bg-dark col-12 col-sm-3 col-lg-2 px-0">
          <Aside />
        </div>
        <main
          className="p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          style={{ minHeight: "90vh" }}
        >
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <>
              <h4 className="text-center font-weight-normal">
                Coupons Management
              </h4>
              <hr className="p-0 m-0" />
              <p className="text-center m-0 p-0">Filter By:</p>
              <Form
                className="d-flex justify-content-around flex-wrap"
                onSubmit={submitFilter}
              >
                <label className="label-control col-3">
                  User
                  <Select name="buyer" className="form-control">
                    <option value={0}>Someone</option>
                    {users.map((item) => {
                      return <option value={item.iduser} key={item.email}>{item.email}</option>;
                    })}
                  </Select>
                </label>
                <label className="label-control col-3">
                  Coupon Status
                  <Select name="status" className="form-control">
                    <option value={null}>Used / Non User</option>
                    <option value={1}>Non User</option>
                    <option value={2}>Used</option>
                  </Select>
                </label>
                <label className="label-control col-3">
                  Start date
                  <Input
                    type="date"
                    className="form-control"
                    name="start_date"
                  />
                </label>
                <label className="label-control col-3">
                  Final Date
                  <Input
                    type="date"
                    className="form-control"
                    name="finaly_date"
                  />
                </label>
                <button type="submit" className="btn btn-primary my-2 col-3">
                  Submit
                </button>
                <button
                  type="reset"
                  className="btn btn-danger my-2 col-3"
                  onClick={async () => {
                    const { data } = await api.get(`/cupom?page=${+page}`);
                    setCupoms(data.cupons);
                    setPages(data.maxPage);
                  }}
                >
                  Reset
                </button>
              </Form>
              <hr />
              <div className="d-flex justify-content-end my-3">
                <button
                  className="btn btn-primary"
                  onClick={async () => {
                    setMostModal(true);
                  }}
                >
                  Add Coupon
                </button>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="border-top-0">
                      <th>Coupon Number</th>
                      <th>User</th>
                      <th>Created at</th>
                      <th>Status</th>
                      <th>Buyer Email</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cupoms.map((item) => {
                      return (
                        <tr key={item.cupom}>
                          <td className="text-truncate py-0 m-0">
                            {item.cupom}
                          </td>
                          <td className="text-truncate py-0 m-0">
                            {item.nome}
                          </td>
                          <td className="text-truncate py-0 m-0">
                            {item.created_at}
                          </td>
                          <td
                            className={
                              item.expirado === 1
                                ? "text-danger text-truncate py-0 m-0"
                                : "text-primary text-truncate py-0 m-0"
                            }
                          >
                            {item.expirado === 1 ? "Used" : "Non Used"}
                          </td>
                          <td className="text-truncate py-0 m-0">
                            {item.email}
                          </td>
                          <td className="text-truncate py-0 m-0">
                            <p
                              className="btn text-danger m-0"
                              onClick={() => deleteCoupon(item.idcupons)}
                            >
                              Remove
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <div className="d-flex align-items-baseline justify-content-end">
            <butoon
              className="btn btn-light shadow"
              onClick={() => {
                if (page > 1) {
                  history.push(`?page=${+page - 1}`);
                }
              }}
            >
              ←
            </butoon>
            <p>
              {page}/{pages}
            </p>
            <butoon
              className="btn btn-light shadow"
              onClick={() => {
                if (pages > page) {
                  history.push(`?page=${+page + 1}`);
                }
              }}
            >
              →
            </butoon>
          </div>
        </main>
      </div>
    </>
  );
}
