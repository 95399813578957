import React, { createContext, useState, useEffect } from "react";

import { ToastContainer } from "react-toastify";

export const Context = createContext({
  token: null,
  setToken: () => null,
  setUsername: () => null,
  username: null,
  showAside: false,
  setShowAside: () => null,
});
export default function ContextProvider({ children }) {
  const [token, setToken] = useState(null);
  const [username, setUsername] = useState(null);
  const [showAside, setShowAside] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("@TOKENSELLER"));
    setUsername(localStorage.getItem("@USERSELLER"));
  }, []);

  useEffect(() => {
    if (window.innerWidth > 576) {
      setShowAside(true);
    }
  }, [window.innerWidth]);

  const value = React.useMemo(() => ({
    token,
    setToken,
    setUsername,
    username,
    showAside,
    setShowAside,
  }), [token]);

  return (
    <>
      <Context.Provider
        value={value}
      >
        {children}
      </Context.Provider>
      <ToastContainer />
    </>
  );
}
