import React from "react";
import { Switch, BrowserRouter, Redirect } from "react-router-dom";
import RouterPrivate from "./Auth/routes";
import Login from "./Pages/Login";
import MyHorses from "./Pages/MyHorses";
import Orders from "./Pages/Orders";
import Contract from "./Pages/Contract";
import CollectionSchedule from "./Pages/CollectionChedule";
import Financial from "./Pages/Financial";
import Settings from "./Pages/Settings";
import Promotion from "./Pages/Promotion";

import ForgotPassword from "./Pages/ForgotPassword";
import Cupons from "./Pages/Cupons";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <RouterPrivate path="/login" component={Login} notPrivate />
        <RouterPrivate path="/orders" component={Orders} />
        <RouterPrivate path="/contract" component={Contract} />
        <RouterPrivate path="/my-horses" component={MyHorses} />
        {/* <RouterPrivate
          path="/collection-schedule"
          component={CollectionSchedule}
        /> */}
        <RouterPrivate path="/financial" component={Financial} />
        <RouterPrivate path="/settings" component={Settings} />
        <RouterPrivate path="/promotion" component={Promotion} />
        <RouterPrivate path="/cupons" component={Cupons} />
        <RouterPrivate
          path="/forgot-password/:token"
          component={ForgotPassword}
          notPrivate
        />

        <Redirect to="/orders?page=1" />
      </Switch>
    </BrowserRouter>
  );
}
