export default function BoxText({
  state,
  setState,
  item,
  index,
  nameCheckbox,
  textCheckBox,
}) {
  return (
    <div className="d-flex flex-column box-text mb-3">
      <textarea
        className="mb-0 w-100 p-2"
        value={item.title}
        onChange={(e) => {
          const array = state.map((stateItem, ind) => {
            if (ind === index) {
              return {
                ...stateItem,
                title: e.target.value,
              };
            } else {
              return stateItem;
            }
          });
          setState(array);
        }}
        onFocus={() => {
          /*if (index + 1 === state.length) {
            setState([...state, { title: "", price: 0 }]);
          }*/
        }}
      ></textarea>
      <div className="d-flex align-items-start">
        <p className="m-0 font-weight-bold" style={{ whiteSpace: "nowrap" }}>
          Price: $
        </p>
        <input
          type="number"
          className="w-100"
          value={item.price}
          onChange={(e) => {
            const array = state.map((stateItem, ind) => {
              if (ind === index) {
                return {
                  ...stateItem,
                  price: Number(e.target.value),
                };
              } else {
                return stateItem;
              }
            });
            setState(array);
          }}
        />
      </div>
      {nameCheckbox && (
        <label className="form-label d-flex flex-row align-items-center text-info text-capitalize">
          <input
            type="radio"
            name={nameCheckbox}
            className="mr-2"
            checked={!!item.standard}
            onChange={(e) => {
              const array = state.map((stateItem, ind) => {
                if (ind === index) {
                  return {
                    ...stateItem,
                    standard: true,
                  };
                } else {
                  return {
                    ...stateItem,
                    standard: false,
                  };
                }
              });
              setState(array);
            }}
          />
          {textCheckBox}
        </label>
      )}
    </div>
  );
}
