import "./aside.css";
import { useContext, useEffect, useState, useRef } from "react";
import { Context } from "../../context";
import { FaPowerOff } from "react-icons/fa";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import api from "../../services/api";

export default function Aside() {
  const linkRef = useRef(null);
  const [stripe_link, setStripeLink] = useState(null);
  const [isVerify, setIsVerify] = useState(true);
  const [isCheckStripeDone, setIsCheckStripe] = useState(false);
  const [linkURL, setLink] = useState("false");

  const context = useContext(Context);
  const params = useParams();

  useEffect(() => {
    if (!isCheckStripeDone) {
      checkStripe();
    }
    setIsCheckStripe(true);
  }, [setIsCheckStripe]);
  const checkStripe = async () => {
    const userID = localStorage.getItem("@IDUSER");

    try {
      const jsondata = await api.post("/checkStripe", {
        userId: userID,
      });
      const data = jsondata.data;
      if (data["needDetails"]) {
        setIsVerify(false);
        setLink(data["link"]);
      } else {
        setIsVerify(true);
      }
    } catch (e) {
      console.log("data error", e.response);
    }
  };
  useEffect(() => {
    (async () => {
      const stripe_linkData = localStorage.getItem("@STRIPE_LINK");
      setStripeLink(stripe_linkData);
      console.log(
        stripe_linkData,
        typeof stripe_linkData,
        JSON.parse(stripe_linkData)
      );
    })();
  }, [params]);
  return (
    <aside
      className="w-100 h-100 bg-dark text-light pt-4 aside px-0"
      style={{ minHeight: "90vh", left: context.showAside ? 0 : -10000 }}
    >
      <a
        href={stripe_link}
        ref={linkRef}
        style={{ display: "none" }}
        target="_blank"
      />
      <div className="d-flex flex-column">
        {stripe_link && stripe_link?.length > 0 && (
          <button
            className="btn btn-primary"
            onClick={async () => {
              localStorage.removeItem("@STRIPE_LINK");
              linkRef.current?.click();
            }}
          >
            <p>Press here to complete your registration</p>
            <strong>(don't leave it for later)</strong>
          </button>
        )}
        <Link
          style={{
            background: useRouteMatch("/orders") ? "#369f9b" : "transparent",
          }}
          to="/orders"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Orders
        </Link>
        <Link
          style={{
            background: useRouteMatch("/contract") ? "#369f9b" : "transparent",
          }}
          to="/contract"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Contract
        </Link>
        <Link
          style={{
            background: useRouteMatch("/my-horses") ? "#369f9b" : "transparent",
          }}
          to="/my-horses"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          My Stallions
        </Link>
        <Link
          style={{
            background: useRouteMatch("/financial") ? "#369f9b" : "transparent",
          }}
          to="/financial?page=1"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Financial
        </Link>{" "}
        <Link
          className="my-2 px-2 font-weight-bold text-light py-2"
          onClick={() => {
            window.open(linkURL);
            // checkStripe()
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>Bank Details</div>
            <div style={{ color: "red", marginLeft: 5 }}>
              {isVerify == true ? "(Verified)" : "(Not Verified)"}
            </div>
          </div>
        </Link>
        <Link
          style={{
            background: useRouteMatch("/promotion") ? "#369f9b" : "transparent",
          }}
          to="/promotion"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Promotion
        </Link>
        <Link
          style={{
            background: useRouteMatch("/cupons") ? "#369f9b" : "transparent",
          }}
          to="/cupons"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Cupons
        </Link>
        <Link
          style={{
            background: useRouteMatch("/settings") ? "#369f9b" : "transparent",
          }}
          to="/settings"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Settings
        </Link>
        <Link
          className="my-2 px-2 font-weight-bold text-danger"
          onClick={() => localStorage.clear()}
        >
          <FaPowerOff color="#dc3545" className="mr-1" />
          Logout
        </Link>
      </div>
    </aside>
  );
}
