import "./orders.css";
import { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import api from "../../services/api";
import {
  FaArrowLeft,
  FaArrowRight,
  FaFileExcel,
  FaPrint,
} from "react-icons/fa";
import ModalOrders from "../../Components/Modals/Orders";
import { Form } from "@unform/web";
import Input from "../../Components/Form/input";
import Select from "../../Components/Form/select";
import { toast } from "react-toastify";
import xlsx from "json-as-xlsx";

export default function Orders() {
  const params = useParams();
  const history = useHistory();
  const formRef = useRef(null);
  const tableRef = useRef(null);

  const [items, setItem] = useState({ data: [] });
  const [mostModal, setMostModal] = useState(false);
  const [formItems, setFormItems] = useState(null);
  const [totals, setTotals] = useState({});
  const [loading, setLoading] = useState(false);

  const page = window.location.search;

  useEffect(() => {
    (async () => {
      setItem({ data: [] });
      if (formItems) {
        submit();
      } else {
        const { data } = await api.get(
          `/orders?page=${Number(page.split("=")[1])}`
        );
        console.log("order data", data);
        setItem(data);
        setTotals(data.total);
      }
      setLoading(false);
    })();
  }, [params, formItems]);

  useEffect(() => {
    if (!mostModal) {
      setMostModal(false);
    }
  }, [mostModal]);

  async function CallApi() {
    setLoading(true);
    const { data } = await api.get(
      `/orders?page=${Number(page.split("=")[1])}`
    );
    setItem(data);
    setTotals(data.total);
    setLoading(false);
  }

  const submit = async (data = formItems) => {
    setFormItems(data);
    let url = `/orders-filter?page=${Number(page.split("=")[1])}`;
    if (data.stallion_name >= 1) {
      url = url + `&idgaranhao=${data?.stallion_name}`;
    }
    if (data.broadmare_name >= 1) {
      url = url + `&idegua=${data?.broadmare_name}`;
    }
    if (data.buyer_name !== "") {
      url = url + `&buyer_name=${data?.buyer_name}`;
    }
    if (data.order_status !== "default") {
      url = url + `&order_status=${data?.order_status}`;
    }
    if (data.pregnant !== "default") {
      url = url + `&pregnancy=${data?.pregnant}`;
    }
    if (data.user_type !== "default") {
      url = url + `&userType=${data?.user_type}`;
    }
    if (data.start_date !== "") {
      url = url + `&start_date=${data?.start_date + " 00:00:00"}`;
    }
    if (data.finaly_date !== "") {
      url = url + `&finaly_date=${data?.finaly_date + " 00:00:00"}`;
    }
    try {
      const { data } = await api.get(url);
      setItem(data);
      setTotals(data.total);
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const clickXlsx = async () => {
    let url = "/orders-filter/csv?page=1";
    if (!formItems?.stallion_name && formItems.stallion_name >= 1) {
      url = url + `&idgaranhao=${formItems?.stallion_name}`;
    }
    if (!formItems?.broadmare_name && formItems.broadmare_name >= 1) {
      url = url + `&idegua=${formItems?.broadmare_name}`;
    }
    if (!formItems?.buyer_name && formItems.buyer_name !== "") {
      url = url + `&buyer_name=${formItems?.buyer_name}`;
    }
    if (!formItems?.order_status && formItems.order_status !== "default") {
      url = url + `&order_status=${formItems?.order_status}`;
    }
    if (!formItems?.pregnant && formItems.pregnant !== "default") {
      url = url + `&pregnancy=${formItems?.pregnant}`;
    }
    if (!formItems?.start_date && formItems.start_date !== "") {
      url = url + `&start_date=${formItems?.start_date + " 00:00:00"}`;
    }
    if (!formItems?.finaly_date && formItems.finaly_date !== "") {
      url = url + `&finaly_date=${formItems?.finaly_date + " 00:00:00"}`;
    }
    try {
      const {
        data: { columns, content },
      } = await api.get(url);
      const settings = {
        sheetName: "Report_fastseme",
        fileName: "Report_fastsemen",
      };
      xlsx(columns, content, settings, true);
      toast.success("Excel file successfully created!");
    } catch (e) {
      toast.error("Error creating file for excel");
      console.log(e);
      console.log(Object.keys(e));
    }
  };

  async function printFunction() {
    for (let i = 1; i <= items.max; i++) {
      let url = `/orders-filter/print?page=${i}`;
      if (!formItems?.stallion_name && formItems.stallion_name >= 1) {
        url = url + `&idgaranhao=${formItems?.stallion_name}`;
      }
      if (!formItems?.broadmare_name && formItems.broadmare_name >= 1) {
        url = url + `&idegua=${formItems?.broadmare_name}`;
      }
      if (!formItems?.buyer_name && formItems.buyer_name !== "") {
        url = url + `&buyer_name=${formItems?.buyer_name}`;
      }
      if (!formItems?.order_status && formItems.order_status !== "default") {
        url = url + `&order_status=${formItems?.order_status}`;
      }
      if (!formItems?.pregnant && formItems.pregnant !== "default") {
        url = url + `&pregnancy=${formItems?.pregnant}`;
      }
      if (!formItems?.start_date && formItems.start_date !== "") {
        url = url + `&start_date=${formItems?.start_date + " 00:00:00"}`;
      }
      if (!formItems?.finaly_date && formItems.finaly_date !== "") {
        url = url + `&finaly_date=${formItems?.finaly_date + " 00:00:00"}`;
      }
      const {
        data: { data },
      } = await api.get(url);
      setItem({ ...items.items, data: [...data, ...items.data] });
    }

    window.print();

    const { data } = await api.get(
      `/orders?page=${Number(page.split("=")[1])}`
    );
    setItem(data);
  }
  return (
    <>
      <div className="deleteForPint">
        <Header />
      </div>
      <div className="d-flex page" style={{ minHeight: "90vh" }}>
        <div className="bg-dark col-12 col-sm-3 col-lg-2 px-0 deleteForPint">
          <Aside />
        </div>
        <main
          className="p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize deleteForPint">Orders</h3>
          <div className="p-4 py-2 mt-4 bg-white">
            <div className="table-responsive">
              <Form
                onSubmit={(data) => {
                  setFormItems(data);
                  history.push(`?page=1`);
                  submit(data);
                }}
                ref={formRef}
                className="deleteForPint"
              >
                <div className="d-flex justify-content-end align-items-baseline">
                  <p>Export to:</p>
                  <button className="btn p-0 border-0" type="submit">
                    <FaFileExcel size={25} onClick={clickXlsx} />
                  </button>
                  <button
                    className="btn p-0 border-0"
                    type="button"
                    onClick={printFunction}
                  >
                    <FaPrint size={25} className="btn p-0 border-0" />
                  </button>
                </div>
                <div className="d-flex flex-wrap">
                  <label className="label-control col-4">
                    Stallion Name
                    <Select name="stallion_name" className="form-control">
                      <option value="0">All/Names</option>
                      {items?.filtro?.cavalos.map((item) => {
                        return (
                          <option value={item.idgaranhao} key={item.idgaranhao}>{item.nome}</option>
                        );
                      })}
                    </Select>
                  </label>
                  <label className="label-control col-4">
                    Broadmare Name
                    <Select
                      name="broadmare_name"
                      id=""
                      className="form-control"
                    >
                      <option value="0">All/Names</option>
                      {items?.filtro?.eguas.map((item) => {
                        return <option value={item.idegua} key={item.idegua}>{item.nome}</option>;
                      })}
                    </Select>
                  </label>
                  <label className="label-control col-4">
                    Username
                    <Input
                      name="buyer_name"
                      placeholder="Search"
                      className="form-control"
                    />
                  </label>
                  <label className="label-control col-4">
                    Order Status
                    <Select name="order_status" id="" className="form-control">
                      <option value="default">Status</option>
                      {items?.filtro?.status.map((item) => {
                        return (
                          <option value={item.status} key={item.status}>{item.statusName}</option>
                        );
                      })}
                    </Select>
                  </label>

                  <label className="label-control col-4">
                    User
                    <Select name="user_type" id="" className="form-control">
                      <option value="default">Type</option>
                      <option value={1}>Veterinarian</option>
                      <option value={2}>Mare Owner</option>
                    </Select>
                  </label>

                  <label className="label-control col-4">
                    Pregnancy Status
                    <Select name="pregnant" id="" className="form-control">
                      <option value="default">Yes/No</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Select>
                  </label>
                  <label className="label-control col-4">
                    Start Date
                    <Input
                      name="start_date"
                      className="form-control"
                      type="date"
                    />
                  </label>
                  <label className="label-control col-4">
                    Finaly Date
                    <Input
                      name="finaly_date"
                      className="form-control"
                      type="date"
                    />
                  </label>
                  <div className="d-flex col-8">
                    <div className="col-6 h-100 pl-0">
                      <button
                        type="submit"
                        className="btn btn-info w-100 h-100"
                      >
                        submit
                      </button>
                    </div>
                    <div className="col-6 h-100 pr-0">
                      <button
                        type="reset"
                        className="btn btn-danger w-100 h-100"
                        onClick={() => setFormItems(null)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
              <table className="table w-100 px-2 mt-2 bg-white" ref={tableRef}>
                <thead className="px-2">
                  <tr>
                    <th className="text-nowrap">Number</th>
                    <th className="text-nowrap">Amount (US $)</th>
                    <th className="text-nowrap">Stallion</th>
                    <th className="text-nowrap">Broodmare</th>
                    <th className="text-nowrap">User Name</th>
                    <th className="text-nowrap">User</th>
                    <th className="text-nowrap">Order Date</th>
                    <th className="text-nowrap">Order Status</th>
                    <th className="text-nowrap">Pregnancy Status</th>
                    <th className="text-nowrap">Order Details</th>
                  </tr>
                </thead>
                <tbody className="px-2">
                  {items?.data?.map((item) => {
                    const statusColor =
                      item.status === "Payment Confirmed"
                        ? "#003087"
                        : item.status === "Delivery"
                        ? "#8031A7"
                        : item.status === "Concluded"
                        ? "#43B02A"
                        : item.status === "Canceled"
                        ? "#f00"
                        : "";

                    const idcompra =
                      item.vendedor.slice(0, 3).toUpperCase() + item.idcompra;
                    return (
                      <>
                        <tr>
                          <td className="text-nowrap">{idcompra}</td>
                          <td className="text-nowrap">{item.valor}</td>
                          <td className="text-nowrap">{item.garanhao}</td>
                          <td className="text-nowrap">{item.egua}</td>
                          <td className="text-nowrap">{item.comprador}</td>
                          <td className="text-nowrap">
                            {item.userType == 2 ? "Mare Owner" : "Veterinarian"}
                          </td>
                          <td className="text-nowrap">{item.date}</td>
                          <td
                            className="text-nowrap"
                            style={{ color: statusColor }}
                          >
                            {item.status}
                          </td>
                          <td className="text-nowrap">{item.prenha}</td>
                          <td className="text-nowrap">
                            <button
                              className="btn py-0 px-2 text-info"
                              style={{
                                boxShadow: "3px 3px 7px #aaa",
                                borderRadius: "20px",
                              }}
                              onClick={() => {
                                setMostModal({
                                  most: true,
                                  data: item.idcompra,
                                });
                              }}
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              <span className="d-flex">
                <p className="mr-4">Orders: {totals?.count}</p>
                <p className="mr-4">Total: {totals?.totalValue}</p>
                <p className="mr-4">
                  Your comission: {+totals?.totalValue - +totals?.adminComisson}
                </p>
              </span>

              <div className="justify-content-end deleteForPint pagination">
                <span className="d-flex mr-auto d-block align-items-baseline">
                  <button
                    style={{ boxShadow: "1px 1px 6px #aaa" }}
                    className="btn btn-light mx-1"
                    onClick={() => {
                      if (Number(page.split("=")[1]) > 1) {
                        return history.push(
                          `?page=${Number(page.split("=")[1]) - 1}`
                        );
                      }
                    }}
                  >
                    <FaArrowLeft size={15} color="#000" />
                  </button>
                  <p>
                    {Number(page.split("=")[1]) || 1}/{items.max}
                  </p>
                  <button
                    style={{ boxShadow: "1px 1px 6px #aaa" }}
                    className="btn btn-light mx-1"
                    onClick={() => {
                      if (Number(page.split("=")[1] < items.max)) {
                        return history.push(
                          `?page=${Number(page.split("=")[1]) + 1}`
                        );
                      }
                    }}
                  >
                    <FaArrowRight size={15} color="#000" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ModalOrders
        show={mostModal.most}
        onHide={async () => {
          setLoading(true);
          setItem({ data: [] });
          if (formItems) {
            submit();
          } else {
            const { data } = await api.get(
              `/orders?page=${Number(page.split("=")[1])}`
            );
            setItem(data);
            setTotals(data.total);
          }
          setLoading(false);
          setMostModal({ most: false });
        }}
        idsale={mostModal.data}
        param={params.param}
        callApi={() => CallApi()}
        style={{ minWidth: "100%" }}
        className="modal-order"
      />
    </>
  );
}
