import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import api from "../../../services/api";
import CurrencyInput from "react-currency-input-field";

export default function AddCoupon(props) {
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState();
  const handleChange = (e) => {
    e.preventDefault();
    const { value = "" } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    setAmount(parsedValue);
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      const money = amount;
      await api.post("/cupom", { userEmail: email, amount: money });
      toast.success("Coupon created successfully!");
    } catch (e) {
      toast.error(e.response.data.message);
    }
    setEmail("");
    props.onHide();
  };
  return (
    <Modal {...props}>
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <p className="font-weight-light h4">Add a new Coupon</p>
          <FaTimes
            color="#aaa"
            style={{ cursor: "pointer" }}
            onClick={props.onHide}
          />
        </div>
        <hr />
        <form className="mx-auto" onSubmit={submit}>
          <label htmlFor="" className="label-control w-100">
            Insert user email to create the coupon
            <input
              type="email"
              style={{
                marginTop: 10,
                width: 320,
                borderWidth: 1,
                borderColor: "lightgray",
                height: 40,
                borderRadius: 5,
              }}
              required
              className="form-control"
              placeholder="someone@somewhere.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <CurrencyInput
              style={{
                marginTop: 10,
                width: 320,
                borderWidth: 1,
                borderColor: "lightgray",
                height: 40,
                padding: 10,
                borderRadius: 5,
              }}
              name="currencyInput"
              id="currencyInput"
              placeholder="Enter Amount"
              value={amount}
              decimalsLimit={2}
              prefix="$"
              onChange={handleChange}
            />
          </label>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary mx-3">
              Add Coupon
            </button>
            <button
              type="reset"
              className="btn btn-danger"
              onClick={props.onHide}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
