import "./modals.css";
import "react-calendar/dist/Calendar.css";
import { Modal, Spinner } from "react-bootstrap";
import api from "../../../services/api";
import { useState, useEffect } from "react";
import { FaTimes, FaSave, FaCamera } from "react-icons/fa";
import { apiUrl } from "../../../config/settings.json";
import Button from "../../../Components/Button";
import { toast } from "react-toastify";
import BoxText from "../../Boxtext";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

export default function ModalUpdateHorses(props) {
  const [loading, setLoading] = useState(false);
  const [photoBase64, setPhotoBase64] = useState(null);
  const [File, setFile] = useState(null);
  const [shipping, setShipping] = useState([
    { price: 0, standard: true, title: "" },
  ]);
  const [contract, setContract] = useState([
    { price: 0, standard: true, title: "" },
  ]);
  const [additional, setAdditional] = useState([{ price: 0, title: "" }]);
  const [name, setName] = useState("");
  const [breed, setBreed] = useState("");
  const [dosesPerDay, setDosesPerDay] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState([]);

  useEffect(() => {
    (async () => {
      if (props.data >= 1) {
        setLoading(true);
        try {
          const { data: cavalo } = await api.get(
            `/garanhao/unique/${props.data}`
          );
          const { data: adictionals } = await api.get(
            `/garanhao/adicionais/${props.data}`
          );
          const { data: shippings } = await api.get(
            `/garanhao/frete/${props.data}`
          );
          const { data: imagem } = await api.get(
            `/search/garanhao/idcavalo/${props.data}`
          );
          const { data: availableDates } = await api.get(
            `/garanhao/dias-funcionamento/${props.data}`
          );
          const { data: contracts } = await api.get(
            `/garanhao/contract/${props.data}`
          );
          setContract(
            contracts.map((ship) => {
              return {
                price: ship.valor,
                standard: ship.standard === 1,
                title: ship.tipos,
                id: ship.idtipos_envio,
              };
            })
          );
          setDate(
            availableDates.map((i) => {
              // console.log(new Date(i.ano,i.dia,i.));
              return new Date(i.data_disponivel?.slice(0, 10));
            })
          );
          console.log(props.data);

          setName(cavalo.nome);
          setBreed(cavalo.raca);
          setDosesPerDay(cavalo.dosesDia);
          setDescription(cavalo.detalhes);
          setPhotoBase64(`${apiUrl}${imagem[0].diretorio}`);
          setLoading(false);
          setShipping(
            shippings.map((ship) => {
              return {
                price: ship.valor,
                standard: ship.standard === 1,
                title: ship.tipos,
                id: ship.idtipos_envio,
              };
            })
          );
          setAdditional(
            adictionals.map((item) => {
              return { price: item.preco, title: item.adicional };
            })
          );
        } catch (e) {
          console.log(e);
          toast.error("Erro");
          setLoading(false);
        }
      }
    })();
  }, [props, props.data, props.show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!photoBase64) {
      return toast.error("Please insert an image of your stallion");
    }

    if (!shipping[0].title) {
      return toast.error("Please enter a shipping method");
    }

    if (!contract[0].title) {
      return toast.error("Please enter a contract method");
    }

    setLoading(true);

    try {
      for (let i = 0; i < date.length; i++) {
        const avaliableDateData = date[i];

        await api.post(`/garanhao/dias-funcionamento/${props.data}`, {
          dia_disponivel: new Date(avaliableDateData)
            .toISOString()
            .slice(0, 10),
        });
      }
      toast.success("Stallion available days successfully changed!");
    } catch (error) {
      console.log(e);
      toast.error(e?.response?.data?.message);
    }
    try {
      await api.put("/garanhao", {
        nome: name,
        detalhes: description,
        raca: breed,
        dosesDia: dosesPerDay,
        garanhao: props.data,
        tipoEnvio: shipping.map((ship) => {
          return {
            tipo: ship.title,
            valor: ship.price,
            standard: ship.standard ? 1 : 0,
            id: ship.idtipos_envio,
          };
        }),
        contractList: contract.map((ship) => {
          return {
            tipo: ship.title,
            valor: ship.price,
            standard: ship.standard ? 1 : 0,
            id: ship.idtipos_envio,
          };
        }),
        adicionais: additional.map((add) => {
          return {
            adicional: add.title,
            valor: add.price,
            id: add.idadicionais,
          };
        }),
      });

      if (File) {
        const formData = new FormData();
        formData.append("garanhao", props.data);
        formData.append("file", File);

        await api.put("/foto", formData);
      }
      toast.success("Successfully bred stallion!");
      props.onHide();
      props.GetHorses();
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
    }

    setLoading(false);
  };

  const deleteHorse = async () => {
    try {
      await api.delete(`/garanhao/delete/${props.data}`);
      toast.success("Stallion successfully deleted!");
      props.onHide();
      props.GetHorses();
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  return (
    <Modal {...props}>
      <Modal.Body
        className="bg-light"
        style={{ minWidth: "70vw", margin: "0 auto" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-danger"
            onClick={() => props.onHide()}
          >
            <FaTimes color="#990000" />
          </button>
        </div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="p-4">
            <h4 className="" style={{ color: "#646464" }}>
              My Stallions
            </h4>
            <div style={{ background: "#fff" }} className="p-4">
              <div>
                <form className="form" onSubmit={handleSubmit}>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-danger mr-5"
                      type="button"
                      onClick={deleteHorse}
                    >
                      Delete horse
                    </button>
                    <Button type="submit">
                      Save <FaSave color="#17a1b8" />
                    </Button>
                  </div>
                  <h5 className="font-weight-normal mb-5">Add photo</h5>
                  <div className="d-flex flex-column ">
                    <label className="" style={{ width: "50%" }}>
                      {photoBase64 ? (
                        <div
                          style={{
                            width: 350,
                            maxHeight: 350,
                            position: "relative",
                          }}
                        >
                          <FaTimes
                            color="#fff"
                            size={15}
                            className="m-auto"
                            onClick={() => setPhotoBase64(null)}
                            style={{
                              position: "absolute",
                              right: -5,
                              top: -5,
                              backgroundColor: "#990000",
                            }}
                          />
                          <img src={photoBase64} style={{ width: "100%" }} />
                        </div>
                      ) : (
                        <span
                          style={{
                            background: "#e9e7e7",
                            padding: 40,
                            position: "relative",
                            height: 100,
                            width: 100,
                            marginBottom: 50,
                          }}
                        >
                          <FaCamera color="#cfcfcf" size={50} />
                        </span>
                      )}
                      <input
                        type="file"
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setFile(file);
                          const fileReader = new FileReader();
                          fileReader.onloadend = () => {
                            setPhotoBase64(fileReader.result);
                          };
                          fileReader.readAsDataURL(file);
                        }}
                      />
                    </label>
                    <div
                      style={{
                        marginTop: photoBase64 ? 0 : 30,
                      }}
                    >
                      <h3>Your days available for this stallion</h3>
                      <DatePicker
                        value={date}
                        onChange={setDate}
                        multiple
                        // style={{ width: "50%", height: 100 }}
                        // containerStyle={{ width: "50%", height: 100 }}
                        style={{ width: "69%", padding: 20 }}
                        containerStyle={{
                          width: "69%",
                        }}
                        plugins={[<DatePanel />]}
                        locale="en-US"
                        // title="dia de "
                      />
                    </div>
                  </div>
                  <h5 className="mt-5">Stallion details</h5>

                  <div className="form-grid">
                    <label className="label-control">
                      Name
                      <input
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                      />
                    </label>
                    <label className="label-control">
                      Breed
                      <input
                        className="form-control"
                        onChange={(e) => setBreed(e.target.value)}
                        value={breed}
                        required
                      />
                    </label>
                    <label className="label-control">
                      Doses Per Day
                      <input
                        className="form-control"
                        type="number"
                        onChange={(e) => setDosesPerDay(e.target.value)}
                        value={dosesPerDay}
                        required
                      />
                    </label>
                  </div>

                  <label className="label-control w-100">
                    Description
                    <textarea
                      className="form-control w-100"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      required
                    ></textarea>
                  </label>

                  <h5>Shipping type available</h5>
                  <div className="form-grid">
                    {shipping.map((item, index) => {
                      return (
                        <BoxText
                          key={item.title}
                          setState={setShipping}
                          state={shipping}
                          item={item}
                          index={index}
                          nameCheckbox="shipping"
                          textCheckBox="this is the default shipping type"
                        />
                      );
                    })}
                  </div>
                  <h5>Contract available</h5>
                  <div className="form-grid">
                    {contract.map((item, index) => {
                      return (
                        <BoxText
                        key={item.title}
                          setState={setContract}
                          state={contract}
                          item={item}
                          index={index}
                          nameCheckbox="contract"
                          textCheckBox="this is the default contract type"
                        />
                      );
                    })}
                  </div>
                  <h5>Add services available</h5>
                  <div className="form-grid">
                    {additional.map((item, index) => {
                      return (
                        <BoxText
                          key={item.title}
                          setState={setAdditional}
                          state={additional}
                          item={item}
                          index={index}
                        />
                      );
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
