import { useEffect, useState } from "react";
import api from "../../services/api";

import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import { Spinner } from "react-bootstrap";
import { FaPenSquare } from "react-icons/fa";
import Button from "../../Components/Button";
import UpdateConfigs from "../../Components/Modals/UpdateConfigs";

export default function Settings() {
  const [infortationsUser, setInformationsUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostConfigs, setMostConfigs] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get("/usuario");
      setInformationsUser(data);
      setLoading(false);
    })();
  }, []);
  return (
    <>
      <Header />
      <UpdateConfigs
        show={mostConfigs}
        onHide={async () => {
          setMostConfigs(false);
          setLoading(true);
          const { data } = await api.get("/usuario");
          setInformationsUser(data);
          setLoading(false);
        }}
      />
      <div className="d-flex" style={{ minHeight: "90vh" }}>
        <div className="bg-dark col-12 col-sm-3 col-lg-2 px-0">
          <Aside />
        </div>
        <main
          className="p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize">Settings</h3>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div style={{ background: "#fff" }} className="p-3 px-5 mt-5">
              <div className="d-flex justify-content-end">
                <Button onClick={() => setMostConfigs(true)}>
                  Edit Information
                  <FaPenSquare size={30} className="ml-2" />
                </Button>
              </div>
              {infortationsUser.length >= 1 &&
              Object.keys(infortationsUser[0]) ? (
                <>
                  {infortationsUser?.map((item) => {
                    const keys = Object.keys(item);
                    const values = Object.values(item);
                    return (
                      <table className="w-100 my-4">
                        <thead className="bg-dark font-weight-bold text-light text-capitalize">
                          <tr>
                            {keys.map((key) => {
                              return (
                                <th key={key}>
                                  {key === null
                                    ? "None"
                                    : String(key)
                                        .replace("_", " ")
                                        .replace("_", " ")}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            {values.map((val) => {
                              return (
                                <td key={val}>
                                  {val === null
                                    ? "None"
                                    : String(val)
                                        .replace("_", " ")
                                        .replace("_", " ")}
                                </td>
                              );
                            })}
                          </tr>
                        </thead>
                      </table>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </main>
      </div>
    </>
  );
}
