import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../../services/api";

export default function CalncelOrder(props) {
  const [selected, setSelected] = useState("1");
  const [value, setValue] = useState("Motivo 01");
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef && textRef.current) {
      if (selected !== "all") {
        console.log(textRef);
        textRef.current.disabled = true;
        textRef.current.value = "";
      } else {
        textRef.current.disabled = false;
      }
    }
  }, [selected, textRef]);

  const submit = async (e) => {
    e.preventDefault();
    if (selected === "all" && textRef.current.value === "") {
      textRef.current.focus();
      return toast.error("Please enter the reason for the cancellation");
    }
    if (selected == "all") {
      setValue(textRef.current.value);
    }

    try {
      await api.post(`/produto-cancelamento`, {
        idcompra: props.idsale,
        text: value,
      });
      toast.info("product status changed successfully");
      props.onHide();
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <h4 className="text-center">Why are you canceling the purchase?</h4>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={submit}>
          <label className="label-control d-flex flex-column">
            <div>
              <input
                onChange={() => {
                  setSelected("1");
                  setValue("Motivo 01");
                }}
                type="radio"
                checked={selected === "1"}
                className="mr-2"
                name="reason"
                id=""
              />
              Motivo 01
            </div>
          </label>
          <label className="label-control d-flex flex-column">
            <div>
              <input
                onChange={() => {
                  setSelected("2");
                  setValue("Motivo 02");
                }}
                type="radio"
                checked={selected === "2"}
                className="mr-2"
                name="reason"
                id=""
              />
              Motivo 02
            </div>
          </label>
          <label className="label-control d-flex flex-column">
            <div>
              <input
                onChange={() => {
                  setSelected("3");
                  setValue("Motivo 03");
                }}
                type="radio"
                checked={selected === "3"}
                className="mr-2"
                name="reason"
                id=""
              />
              Motivo 03
            </div>
          </label>
          <label className="label-control d-flex flex-column">
            <div>
              <input
                onChange={() => {
                  setSelected("4");
                  setValue("Motivo 04");
                }}
                type="radio"
                checked={selected === "4"}
                className="mr-2"
                name="reason"
                id=""
              />
              Motivo 04
            </div>
          </label>
          <label className="label-control d-flex flex-column">
            <div>
              <input
                onChange={() => {
                  setSelected("5");
                  setValue("Motivo 05");
                }}
                type="radio"
                checked={selected === "5"}
                className="mr-2"
                name="reason"
                id=""
              />
              Motivo 05
            </div>
          </label>
          <label className="label-control d-flex flex-column">
            <div>
              <input
                onChange={() => {
                  setSelected("6");
                  setValue("Motivo 06");
                }}
                type="radio"
                checked={selected === "6"}
                className="mr-2"
                name="reason"
                id=""
              />
              Motivo 06
            </div>
          </label>
          <label className="label-control d-flex flex-column">
            <div>
              <input
                onChange={() => setSelected("all")}
                type="radio"
                checked={selected === "all"}
                className="mr-2"
                name="reason"
                id=""
              />
              Other
            </div>
            <textarea
              ref={textRef}
              name=""
              id=""
              cols="30"
              rows="5"
              className="form-control"
              onFocus={() => setSelected("all")}
              onChange={(e) => setValue(e.target.value)}
            ></textarea>
          </label>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-info">
              Submit
            </button>
            <button
              type="reset"
              className="btn btn-danger ml-3"
              onClick={props.onHide}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
