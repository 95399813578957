import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../../services/api";
export default function ModalForgotPassword(props) {
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("/recuperar-senha", { email, tipo: "vendedor" });
      toast.success("Email successfully sent");
      localStorage.setItem("@USEREMAIL", email);
    } catch (e) {
      toast.error(e.response.data.message);
    }
    setEmail("");
  };
  return (
    <Modal {...props}>
      <Modal.Body>
        <h4>Enter your email</h4>
        <form className="d-flex" onSubmit={handleSubmit}>
          <input
            className="form-control"
            placeholder="EMail"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="btn btn-info ml-2">
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
