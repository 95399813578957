import { useRef } from "react";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import LogoImg from "../../assets/logo.svg";
import { toast } from "react-toastify";

export default function Financial() {
  const params = useParams();
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const functionMostPassword = () => {
    passwordRef.current.type =
      passwordRef.current.type === "text" ? "password" : "text";
    confirmPasswordRef.current.type =
      confirmPasswordRef.current.type === "text" ? "password" : "text";
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      return toast.error("Your passwords not match");
    }
    await api.post("/troca-senha", {
      email: localStorage.getItem("@USEREMAIL"),
      tipo: "vendedor",
      senha: passwordRef.current.value,
      token: params.token,
    });

    toast.success("Passwords successfully changed!");
    window.location.href = "/";
  };

  return (
    <>
      <header className="bg-dark py-3 px-5">
        <img
          src={LogoImg}
          style={{ width: "120px" }}
          onClick={() => (window.location.href = "/")}
        />
      </header>
      <div className="bg-light py-5" style={{ minHeight: "88vh" }}>
        <div className="col-11 col-sm-9 col-md-7 m-auto p-1 p-sm-2 p-md-4 bg-white shadow rounded">
          <h3 className="col-12 col-md-10 mx-auto">Enter A New Password</h3>
          <form
            className="d-flex flex-column col-12 col-md-10 mx-auto"
            onSubmit={onSubmit}
          >
            <label className="label-control">
              Enter your new password
              <input
                ref={passwordRef}
                className="form-control"
                type="password"
              />
            </label>
            <label className="label-control">
              Confirm your new password
              <input
                ref={confirmPasswordRef}
                className="form-control"
                type="password"
              />
            </label>
            <label>
              <input
                type="checkbox"
                className="mr-1"
                onClick={functionMostPassword}
              />
              Most password
            </label>

            <button type="submit" className="btn btn-info">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
