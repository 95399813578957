import { Redirect, Route } from "react-router-dom";

export default function RouterPrivate(props) {
  if (!props.notPrivate) {
    if (
      !localStorage.getItem("@TOKENSELLER") ||
      !localStorage.getItem("@USERSELLER")
    ) {
      return <Redirect to="/login" />;
    }
  }

   return <Route {...props} />;
}
