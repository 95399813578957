import "./MyHorses.css";
import React, { useState, useEffect } from "react";
import { apiUrl } from "../../config/settings.json";

import { FaPlus } from "react-icons/fa";

import api from "../../services/api";
import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import Button from "../../Components/Button";
import { Spinner } from "react-bootstrap";
import ModalCreateHorses from "../../Components/Modals/CreateHorses";
import ModalUpdateHorses from "../../Components/Modals/UpdateHorses";
export default function MyHorses() {
  const [horse, setHorse] = useState({ data: [], breeds: [] });
  const [raca, setRaca] = useState("all");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modifyHorse, setModifyHorse] = useState({ most: false, data: null });

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (raca === "all") {
        const { data } = await api.get("/vendedor/cavalos");
        console.log(data);
        setHorse(data);
      } else {
        const { data } = await api.get(`/vendedor/cavalos/${raca}`);
        setHorse(data);
      }
      setLoading(false);
    })();
  }, [raca]);

  async function GetHorses() {
    setLoading(true);
    if (raca === "all") {
      const { data } = await api.get("/vendedor/cavalos");
      setHorse(data);
    } else {
      const { data } = await api.get(`/vendedor/cavalos/${raca}`);
      setHorse(data);
    }
    setLoading(false);
  }

  return (
    <>
      <ModalCreateHorses
        show={showModal}
        onHide={() => setShowModal(false)}
        GetHorses={GetHorses}
        style={{ minWidth: "100%" }}
        className="modal-order"
      />
      <ModalUpdateHorses
        show={modifyHorse.most}
        onHide={() => setModifyHorse({ most: false, data: null })}
        GetHorses={GetHorses}
        data={modifyHorse.data}
        style={{ minWidth: "100%" }}
        className="modal-order"
      />
      <Header />
      <div className="d-flex" style={{ minHeight: "90vh" }}>
        <div className="bg-dark col-12 col-sm-3 col-lg-2 px-0">
          <Aside />
        </div>
        <main
          className="p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize">My Stallions</h3>

          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div style={{ background: "#fff" }} className="p-3 mt-5">
              <div className="d-flex justify-content-between">
                <select
                  className="col-4 form-control"
                  onChange={(e) => {
                    setRaca(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Select Stallion
                  </option>
                  <option value="all">All</option>
                  {horse.breeds?.map((item) => {
                    return <option value={item?.idraca} key={item?.idraca}>{item?.raca}</option>;
                  })}
                </select>
                <Button onClick= {()=>{
                  setShowModal(true)
                  
                }}  
                >
                  Register New Stallion <FaPlus color="#17a2b8" size={16} />
                </Button>
              </div>
              <div className="grid">
                {horse.data.map((item) => {
                  return (
                    <div
                      key={item.idgaranhao}
                      className="d-flex flex-column align-items-center btn"
                      onClick={() => {
                        setModifyHorse({ most: true, data: item.idgaranhao });
                      }}
                    >
                      <img src={apiUrl + item.diretorio} />
                      <p
                        className="px-3 py-2 w-100"
                        style={{
                          boxShadow: "2px 2px 6px #aaa",
                          borderRadius: "30px",
                        }}
                      >
                        {item.nome}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
