import "./modals.css";

import { Modal, Spinner } from "react-bootstrap";
import api from "../../../services/api";
import { useState, useEffect } from "react";
import { FaTimes, FaTruck } from "react-icons/fa";
import { apiUrl } from "../../../config/settings.json";
import Button from "../../../Components/Button";
import { toast } from "react-toastify";
import CancelOrder from "../CancelOrder";

export default function ModalOrders(props) {
  const [sale, setSale] = useState({});
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [mostCancelOrder, setMostCancelOrder] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get(`/orders-details/${props.idsale}`);
      console.log("order details sunny", data);
      setSale(data);
      setLoading(false);
    })();
  }, [props, props.idsale]);

  const submitCode = async (e) => {
    e.preventDefault();
    try {
      await api.post("/orders/codigo-envio", { code, idcompra: props.idsale });
      toast.success("Code successfully inserted");
      setCode("");
      props.onHide();
      props.callApi();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal {...props}>
      <CancelOrder
        show={mostCancelOrder}
        onHide={async () => {
          props.onHide();
          setMostCancelOrder(false);
        }}
        idsale={props.idsale}
      />
      <Modal.Body
        className="bg-light"
        style={{ minWidth: "70vw", margin: "0 auto" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-danger"
            onClick={() => props.onHide()}
          >
            <FaTimes color="#990000" />
          </button>
        </div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="p-4">
            <h4 className="" style={{ color: "#646464" }}>
              Details | Order: {props.idsale}
            </h4>
            <div style={{ background: "#fff" }} className="conteiner-modal p-4">
              {sale?.saleData?.status === "authorised" && (
                <div className="d-flex align-items-baseline">
                  <p className="text-info">
                    Enter your product's tracking code here:{" "}
                  </p>
                  <form
                    className="d-flex input-button col-6 p-0 ml-2"
                    style={{ boxShadow: "3px 3px 6px #aaa", height: 40 }}
                    onSubmit={submitCode}
                  >
                    <input
                      className="pl-3 col-9"
                      placeholder="Code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <button className="col-3">
                      <FaTruck color="#fff" size={30} />
                    </button>
                  </form>
                  <br />
                </div>
              )}
              <div className="d-flex">
                <img src={`${apiUrl}/${sale?.saleData?.diretorio}`} />
                <div className="px-3" style={{ borderRight: "2px solid #aaa" }}>
                  <p>
                    STALLION STATION:
                    <strong>{sale?.saleData?.vendedor}</strong>
                  </p>
                  <h4>{sale?.saleData?.nome}</h4>
                  <h4>$ {sale?.saleData?.valor?.toFixed(2)}</h4>
                </div>
                <div className="ml-3">
                  <p className="m-0 p-0">Collection Date</p>
                  <div className="d-flex mb-3">
                    <span className="bg-light p-1">
                      Month: {sale?.saleData?.mes}
                    </span>
                    <span className="bg-light p-1 ml-3">
                      Day: {sale?.saleData?.dia}
                    </span>
                  </div>
                  {sale?.saleData?.code?.length >= 1 && (
                    <span className="bg-light p-1">
                      tracking code: <strong>{sale?.saleData?.code}</strong>
                    </span>
                  )}
                  <br />
                  <br />
                  {sale?.saleData?.status === "delivery" && (
                    <span className="bg-light p-1">
                      Mare Is pregnant?{" "}
                      <strong>
                        {sale?.prenha?.prenha === 0
                          ? "No"
                          : sale?.prenha?.prenha === 1
                          ? "Yes"
                          : "not specified"}
                      </strong>
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <h5>More Information</h5>
                <div>
                  {sale?.saleData?.status === "await-payment" ? (
                    <>
                      <Button
                        onClick={async () => {
                          try {
                            await api.get(
                              `/pagamento-recebido/${props.idsale}`
                            );
                            toast.success(
                              "product status changed successfully"
                            );
                            props.onHide();
                            props.callApi();
                          } catch (e) {
                            toast.error(e.response.data.message);
                          }
                        }}
                      >
                        Payment received
                      </Button>
                      <button
                        className="mx-3 btn btn-danger"
                        onClick={async () => {
                          alert("aaa");
                          setMostCancelOrder(true);
                        }}
                      >
                        Calcel Order
                      </button>
                    </>
                  ) : sale?.saleData?.status === "delivery" ? (
                    <>
                      <Button
                        onClick={async () => {
                          try {
                            await api.get(`/produto-recebido/${props.idsale}`);
                            toast.success(
                              "product status changed successfully"
                            );
                            props.onHide();
                            props.callApi();
                          } catch (e) {
                            toast.error(e.response.data.message);
                          }
                        }}
                      >
                        Product already delivered
                      </Button>
                    </>
                  ) : sale?.saleData?.status === "authorised" ? (
                    <button
                      className="mx-3 btn btn-danger"
                      onClick={async () => {
                        setMostCancelOrder(true);
                      }}
                    >
                      Calcel Order
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {sale?.items?.map((item) => {
                const keys = !!item.data ? Object.keys(item?.data) : [];
                const values = !!item.data ? Object.values(item?.data) : [];
                return (
                  <>
                    <h5>{item.title}</h5>
                    <table className="w-100">
                      <thead className="bg-light">
                        <tr>
                          {keys.map((key) => {
                            return (
                              <th className="text-capitalize">
                                {String(
                                  key === "buyer_name" ? "user_name" : key
                                ).replace("_", " ")}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          {values.map((val) => {
                            return (
                              <td className="text-capitalize">
                                {String(val).replace("_", " ")}
                              </td>
                            );
                          })}
                        </tr>
                      </thead>
                    </table>
                    <hr />
                  </>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
