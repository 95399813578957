import React, { useContext, useState } from "react";
import { Context } from "../../context";
import api from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import LogoImage from "../../assets/logo.svg";
import ModalForgotPassword from "../../Components/Modals/ForgotPassword";

export default function Login() {
  const context = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mostForgotPass, setMostForgotPass] = useState(false);

  const submitForm = async (e) => {
    
    e.preventDefault();

    try {
      const { data } = await api.post("/login", {
        usuario: email,
        senha: password,
        tipo: "vendedor",
      });
      localStorage.setItem("@TOKENSELLER", data.token);
      localStorage.setItem("@TOKENSELLER", data.token);
      localStorage.setItem("@USERSELLER", data.usuario);
      localStorage.setItem("@IDUSER", data.iduser);
      context.setToken(data.token);
      context.setUsername(data.usuario);
      toast.success("Login successfully!");
      window.location.href = "/";
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <ModalForgotPassword
        show={mostForgotPass}
        onHide={() => setMostForgotPass(false)}
      />
      <div
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center bg-dark"
      >
        <form className=" mx-auto p-5 rounded col-4" onSubmit={submitForm}>
          <div className="d-flex justify-content-center">
            <img
              src={LogoImage}
              className="text-center"
              style={{ width: "45%" }}
            />
          </div>
          <h2 className="text-center text-light w-100">
            Stallion Station Login
          </h2>
          <label className="label-control text-light w-100">
            Email
            <input
              className="form-control"
              placeholder="Insert here your username or email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </label>
          <br />
          <label className="label-control text-light w-100">
            Password
            <input
              className="form-control"
              type="password"
              placeholder="Insert here yor password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </label>
          <small
            className="text-white btn p-0 m-0"
            onClick={() => setMostForgotPass(true)}
          >
            Forgot password
          </small>
          <br />
          <button className="btn btn-info ml-auto">Submit</button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
